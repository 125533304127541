import {
    ADD_SURVEY_VERSION_TO_ACTIVE_SURVEY,
    CHANGE_SELECTED_SURVEY,
    GET_FOLLOW_UPS_SUCCESS,
    GET_SINGLE_SURVEY,
    GET_SURVEY_DATA_SUCCESS,
    GET_SURVEY_IMPORT_ROWS_SUCCESS,
    GET_SURVEY_IMPORTS_SUCCESS,
    GET_SURVEY_SEND_IMPORT_SCHEMA_SUCCESS,
    GET_SURVEY_STATS_SUCCESS,
    GET_SURVEY_SUCCESS,
    GET_SURVEY_SAVED_VERSIONS,
    GET_SYSTEM_LANGUAGES_SUCCESS,
    REMOVE_SURVEY_VERSION_TO_ACTIVE_SURVEY,
    UPDATE_ACTIVE_SURVEY_IN_SURVEY_DEFINITION,
    UPDATE_OR_SAVE_SURVEY_VERSION, EXCLUDE_COMMENT_UPDATE
} from "./actions";


const initialState = {
    surveys: [],
    surveyStats: [],
    activeSurvey: null,
    activeSurveyVersions: null,
    sendSurveyImportSchema: [],
    surveyImports: {},
    importRows: {},
    followUps: [],
    savedSurveyVersions: [],
    systemLanguages: [],
    selectedVersionId: null,
    excludeComment: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SURVEY_SUCCESS:
            return {
                ...state,
                surveys: action.payload,
            };
        case GET_FOLLOW_UPS_SUCCESS:
            return {
                ...state,
                followUps: action.payload,
            };
        case GET_SINGLE_SURVEY:
            // Payload is the requested survey ID. If that ID is changing then clear out the active items
            // TODO: check if this is correct at all
            if (state.activeSurvey && state.activeSurvey.id != action.payload)
                return {
                    ...state,
                    activeSurvey: null,
                    activeSurveyVersions: null,
                };
        case GET_SURVEY_DATA_SUCCESS:
            return {
                ...state,
                activeSurvey: action.payload.survey,
                activeSurveyVersions: action.payload.versions,
                selectedVersionId: action.payload.selectVersion
            };
        case GET_SURVEY_STATS_SUCCESS:
            return {
                ...state,
                surveyStats: action.payload,
            };
        case GET_SURVEY_SAVED_VERSIONS:
            const savedVersionIndex = state.savedSurveyVersions.findIndex(savedSurvey => savedSurvey.surveyId === action.payload.surveyId && savedSurvey.surveyVersionId === action.payload.surveyVersionId);
            if (savedVersionIndex === -1 && !action.payload.activated){
                return {
                    ...state,
                    savedSurveyVersions: [...state.savedSurveyVersions, action.payload],
                }
            }
            if(savedVersionIndex > -1 && action.payload.activated) {
                const updatedSavedSurveyVersions = [
                    ...state.savedSurveyVersions.slice(0, savedVersionIndex),
                    ...state.savedSurveyVersions.slice(savedVersionIndex + 1)
                ];
                return {
                    ...state,
                    savedSurveyVersions:updatedSavedSurveyVersions,
                }
            }
            return {
                ...state,
            };
        case GET_SURVEY_SEND_IMPORT_SCHEMA_SUCCESS:
            return {
                ...state,
                sendSurveyImportSchema: action.payload,
            };
        case GET_SURVEY_IMPORTS_SUCCESS:
            return {
                ...state,
                surveyImports: {
                    ...state.surveyImports,
                    [action.surveyId]: action.payload
                },
            };
        case GET_SURVEY_IMPORT_ROWS_SUCCESS:
            return {
                ...state,
                importRows: {
                    ...state.importRows,
                    [action.importId]: action.payload
                },
            };
        case GET_SYSTEM_LANGUAGES_SUCCESS:
            return {
                ...state,
                systemLanguages: action.payload,
            };
        case UPDATE_ACTIVE_SURVEY_IN_SURVEY_DEFINITION:
            const notActiveSurveVersions = state.activeSurveyVersions.filter(version => version.id !== action.payload.activeSurveyVersion.id);
            return {
                ...state,
                activeSurvey: action.payload,
                activeSurveyVersions: [...notActiveSurveVersions, action.payload.activeSurveyVersion]
            };
        case ADD_SURVEY_VERSION_TO_ACTIVE_SURVEY:
            return {
                ...state,
                activeSurveyVersions: [...state.activeSurveyVersions, action.payload],
                selectedVersionId: action.payload.id
            };
        case REMOVE_SURVEY_VERSION_TO_ACTIVE_SURVEY:
            let filteredVersions = state.activeSurveyVersions.filter(version => version.id !== action.payload);
            let maxVersion = filteredVersions.reduce((prev, current) => {
                return (prev.id > current.id) ? prev : current;
            }, filteredVersions[0] || null);

            return {
                ...state,
                activeSurveyVersions: filteredVersions,
                selectedVersionId: maxVersion.id
            };
        case UPDATE_OR_SAVE_SURVEY_VERSION:
            const indexSurveyVersionToUpdate = state.activeSurveyVersions.findIndex(surveyVersion => surveyVersion.id === action.payload.id);
            if (indexSurveyVersionToUpdate !== -1) {
                const updatedSurveyVersion = {
                    ...state.activeSurveyVersions[indexSurveyVersionToUpdate],
                    ...action.payload
                }

                const updatedSurveyVersions = [
                    ...state.activeSurveyVersions.slice(0, indexSurveyVersionToUpdate),
                    updatedSurveyVersion,
                    ...state.activeSurveyVersions.slice(indexSurveyVersionToUpdate + 1)
                ]

                return {
                    ...state,
                    activeSurveyVersions: updatedSurveyVersions,
                }
            }

            return {
                ...state,
            }
        case CHANGE_SELECTED_SURVEY:
            return {
                ...state,
                selectedVersionId: action.payload
            }
        case EXCLUDE_COMMENT_UPDATE:
            return {
                ...state,
                excludeComment: action.payload
            }
        default:
            return state;
    }
}