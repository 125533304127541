import React from 'react';
import PositivityFeedbackCell from "../positivity/PositivityFeedbackCell";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Masonry from "react-masonry-css";
import useInterval from 'beautiful-react-hooks/useInterval';

import { getLocalDateStringFromUTC } from "@/common/LocalTimeStamp";
import  { hideAuthorsFromFocusBoard } from "@/shared/access";
import SurveyResults from "../survey/SurveyResults";
import _ from "lodash";
import useDocumentTitle from "../../common/UseDocumentTitle";

function PositivityIndex() {
    useDocumentTitle('PowerEngage - Feedback');
    const LIST_TAB = "LIST";
    const FOCUS_TAB = "FOCUS";
    const FULL_SCREEN_CLASS = "fullScreen";
    const [feedback, setFeedback] = React.useState([]);
    const [lastFeedbackLoadedAt, setLastFeedbackLoadedAt] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(LIST_TAB);
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.remove(FULL_SCREEN_CLASS);
        if (activeTab === FOCUS_TAB) {
            document.body.classList.add(FULL_SCREEN_CLASS);
        }
    }, [activeTab])

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        }
    };

    const backgroundColor1 = {
        backgroundColor: "#6550B9"
    }
    const backgroundColor2 = {
        background: "#00A2C7"
    }
    const backgroundColor3 = {
        background: "#0D74CE"
    }

    const colors = [
        backgroundColor1,
        backgroundColor2,
        backgroundColor3
    ]

    const loadFeedback = (since) => {
        if (requestInProgress) {
            return;
        }
        setRequestInProgress(true);
        let params = '';

        if (since > 0) {
            params += '?since=' + since;
        }
        API.get('Core', '/api/v1/feedback-board' + params, { timeout: 90000 })
            .then(response => {
                if (response.status !== "NO_NEW_DATA") {
                    setFeedback(Object.assign([], response));
                    if (feedback.lastUpdated) {
                        const timestamp = new Date(feedback.lastUpdated).getTime();
                        setLastFeedbackLoadedAt(timestamp);
                    }
                }
            })
            .catch(error => {
                toast("Could not load feedback: " + error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.ERROR
                });
            })
            .finally(() => {
                setRequestInProgress(false);
            });
    }

    useInterval(() => {
        loadFeedback(lastFeedbackLoadedAt);
    }, 90000);

    React.useEffect(() => {
        loadFeedback(lastFeedbackLoadedAt);
    }, [])

    const carouselRef = React.useRef(null);

    // Manage focus for the carousel items
    React.useEffect(() => {
        if (carouselRef.current?.querySelector) {
            const activeSlide = carouselRef.current.querySelector('.selected');
            if (activeSlide) {
                activeSlide.focus();
            }
        }
    }, [feedback]);

    const renderCustomPrevArrow = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button type="button"
                    className="control-arrow control-prev"
                    onClick={onClickHandler} title={label} aria-label={label}
                    alt={label}>
            </button>
        );

    const renderCustomNextArrow = (onClickHandler, hasNext, label) =>
        hasNext && (
            <button type="button"
                    className="control-arrow control-next"
                    onClick={onClickHandler} title={label} aria-label={label}
                    alt={label}>
            </button>
        );

    return (
        <div className="rootView positivityView">
        <div className="navBar">
                <h1 className="title">Feedback Board</h1>

                <div className="actions" role="tablist">
                    <div className={`segmentedBar index${activeTab === LIST_TAB ? '0' : '1'}`} style={{ width: "172px" }}>
                        <div onClick={setActiveTab.bind(this, LIST_TAB)} className={`segment ${activeTab === LIST_TAB ? ' selected' : ''}`}>
                            <div className="title" role="tab">List</div>
                        </div>

                        <div onClick={setActiveTab.bind(this, FOCUS_TAB)} className={`segment ${activeTab === FOCUS_TAB ? ' selected' : ''}`}>
                            <div className="title" role="tab">Focused</div>
                        </div>
                        <div className="selection" />
                    </div>
                </div>

                <div className="separator"></div>
            </div>
                {
                    activeTab === LIST_TAB ? (
                        <div className="scrollView">
                            <TransitionGroup className="feedbackList tableView">
                            <Masonry className={"feedback-masonry"} breakpointCols={2} columnClassName={"feedback-masonry-column"}>
                                {
                                    _.map(feedback.items, function (f, i) {
                                        let color = colors[(i % 3)];
                                        return (
                                            <CSSTransition
                                                key={f.id}
                                                timeout={5000}
                                                classNames="feedback-item"
                                            >
                                                <SurveyResults surveyInstanceId={f.surveyInstanceId}>
                                                    <PositivityFeedbackCell
                                                        extraClasses={"clickable-feedback"}
                                                        feedbackStyle={color}
                                                        feedbackText={f.feedback}
                                                        surveyId={f.surveyId}
                                                        feedbackSubject={f.feedbackSubject}
                                                        encounterPersonnel={f.encounterPersonnel}
                                                    />
                                                </SurveyResults>
                                            </CSSTransition>
                                        )
                                    })
                                }
                            </Masonry>
                        </TransitionGroup>
                        </div>
                    ) : (
                        <div className="focusView">
                            <div className={"feedbackList tableView"}>
                            <Carousel
                                ref={carouselRef}
                                renderArrowPrev={(onClickHandler, hasPrev) =>
                                    renderCustomPrevArrow(onClickHandler, hasPrev, 'Previous item')
                                }
                                renderArrowNext={(onClickHandler, hasNext) =>
                                    renderCustomNextArrow(onClickHandler, hasNext, 'Next item')
                                }
                                centerMode={false}
                                showArrows={true}
                                showStatus={false}
                                showIndicators={false}
                                infiniteLoop={true}
                                showThumbs={false}
                                autoPlay={true}
                                interval={10000}
                                transitionTime={600}
                                stopOnHover={false}
                            >
                                {
                                    _.map(feedback.items, function (f, i) {
                                        let color = colors[(i % 3)];
                                        let responders, all, callTakers, responderNames, callTakerNames, allNames = new Set();

                                        if (f.personnel != null) {
                                            responders = new Set(f.personnel.filter(ep => ep.type === "RESPONDER").map(resp => resp.name));
                                            callTakers = new Set(f.personnel.filter(ep => ep.type === "CALLTAKER").map(ct => ct.name));
                                            all = new Set(f.personnel.map(p => p.name));
                                            responderNames = Array.from(responders);
                                            callTakerNames = Array.from(callTakers);
                                            allNames = Array.from(all);
                                        }

                                        return (
                                            <div style={Object.assign(color)}
                                                 className="feedbackCell focusedFeedback"
                                                 key={f.surveyAnswerId}
                                                 tabIndex="0" // Ensure each slide can be focused for screen readers
                                                 role="group"
                                                 aria-label={`Item ${i + 1} of ${feedback.items.length}`}
                                                >
                                                {f.feedback != null && f.feedback.length >= 400 ?
                                                    <div className="feedbackText small">{f.feedback}</div> :
                                                    f.feedback != null && f.feedback.length >= 200 && f.feedback.length < 400 ?
                                                    <div className="feedbackText medium">{f.feedback}</div> :
                                                    <div className="feedbackText big">{f.feedback}</div>
                                                }
                                                <div style={styles.container}>
                                                    <span className="feedbackDate">{getLocalDateStringFromUTC(f.date, "MM/DD/YYYY, h:mm:ss a")}</span>
                                                    {
                                                        f.personnel && f.feedbackSubject && f.feedbackSubject === "CALLTAKER" && !hideAuthorsFromFocusBoard() ? (
                                                            <p className="feedbackAuthor" style={{fontSize: '20px'}}>{callTakerNames.join(' - ')}</p>
                                                        ) : null
                                                    }
                                                    {
                                                        f.personnel && f.feedbackSubject && f.feedbackSubject === "RESPONDER" && !hideAuthorsFromFocusBoard() ? (
                                                            <p className="feedbackAuthor" style={{fontSize: '20px'}}>{responderNames.join(' - ')}</p>
                                                        ) : null
                                                    }
                                                    {
                                                        f.personnel && f.feedbackSubject && f.feedbackSubject === "ALL" && !hideAuthorsFromFocusBoard() ? (
                                                            <p className="feedbackAuthor" style={{fontSize: '20px'}}>{allNames.join(' - ')}</p>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                        </div>
                    )
                }
        </div>
    );
}

export default (PositivityIndex);