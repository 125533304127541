export const createProfileConfig = (user, handleLogout, toggleThem, tenantName, topNavTheme) => {
    const {firstName, lastName, lightTheme} = user || {};
    return {
        isEnabled: true,
        avatar: {
            firstName: firstName || '',
            lastName: lastName || '',
            photoUrl: null
        },
        profileMenuItems: [
            {
                label: "Logout",
                clickable: {
                    type: "button",
                    onClick: () => {
                        handleLogout();
                        return {
                            preventOverlayClose: false
                        };
                    }
                }
            },
            ...(!topNavTheme ? [{
                label: lightTheme ? "Use Dark Theme" : "Use Light Theme",
                clickable: {
                    type: "button",
                    onClick: () => {
                        toggleThem();
                        return {
                            preventOverlayClose: false
                        };
                    }
                }
            }] : []),
            {
                label: tenantName || '\u00A0',
                disabled: true,
                clickable: {
                    type: "button",
                    onClick: () => {
                        return {
                            preventOverlayClose: false
                        };
                    }
                }
            },
        ]
    };
};