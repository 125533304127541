import React, {createContext, useEffect, useState} from 'react';
import getOidcServiceInstance from "./EIDOidcSingletonService";

const EidAuthContext = createContext();

const EidAuthProvider = ({children}) => {
    const [eidOidcService, setOidcService] = useState(null);
    const [eidUser, setEidUser] = useState(() => {
        const eidSignOut = localStorage.getItem('eidSignOut');
        if (eidSignOut) {
            localStorage.removeItem('eidUser');
            return null;
        }

        const eidUserInSession = localStorage.getItem('eidUser');
        if (!eidUserInSession) {
            return null;
        }
        try {
            return JSON.parse(eidUserInSession);
        } catch (error) {
            console.error('Invalid JSON in localStorage for eidUser:', error);
            localStorage.removeItem('eidUser');
            return null;
        }
    });

    useEffect(() => {
        let oidcInstance, handleAccessTokenExpired;
        const initializeEidSdk = async () => {
            oidcInstance = await getOidcServiceInstance();
            setOidcService(oidcInstance);
            handleAccessTokenExpired = () => {
                eidUserSignOut();
            };
            oidcInstance?.events.addAccessTokenExpired(handleAccessTokenExpired);
            try {
                let currentUser = await oidcInstance?.getUser();
                handleUserLoaded(currentUser);
            } catch (error) {
                console.error('Error during user retrieval:', error);
                handleEidUserError();
            }

        };

        if (!localStorage.getItem('eidSignOut')) {
            initializeEidSdk();
        }
        return () => {
            if (oidcInstance?.events) {
                oidcInstance.events.removeAccessTokenExpired(handleAccessTokenExpired);
            }
        };
    }, []);

    const handleEidUserError = () => {
        setEidUser(null);
        localStorage.removeItem('eidUser');
    };

    const handleUserLoaded = (user) => {
        setEidUser(user);
        localStorage.setItem('eidUser', JSON.stringify(user));
    };

    const setSignInEidUser = (eidUser) => {
        setEidUser(eidUser);
        if (eidUser) {
            localStorage.setItem('eidUser', JSON.stringify(eidUser));
        }
    };

    const eidUserSignOut = async () => {
        localStorage.setItem('eidSignOut', 'true');
        try {
            const oidcInstance = eidOidcService || await getOidcServiceInstance();
            await oidcInstance?.signOut();
        } catch (error) {
            console.error('Error during sign-out:', error);
        }
    };

    return (
        <EidAuthContext.Provider value={{eidUser, setSignInEidUser, eidUserSignOut}}>
            {children}
        </EidAuthContext.Provider>
    );
};

export {EidAuthContext, EidAuthProvider};