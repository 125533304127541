import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notification from "@/common/Notification";
import Routes from '@/routes/Routes';
import Sidebar from '@/routes/Sidebar';
import {refreshTenantFeatures} from '@/shared/user/actions';
import '@/styles/styles.sass';
import {enabledFeatureRoutes} from "@/utils/utils";
import {useHistory} from "react-router-dom";
import { isTopNavEnabled} from "./shared/access";
import {resetRedirectHome} from "./shared/user/actions";

function AppLayout(props) {
    let {childProps} = props;
    const routes = (<Routes childProps={childProps}/>);
    const history = useHistory();
    const dispatch = useDispatch();
    const shouldRedirectHome = useSelector(state => state.logout.shouldRedirectHome);

    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (enabledFeatureRoutes.test(location.pathname)) {
                dispatch(refreshTenantFeatures());
            }
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch]);

    React.useEffect(() => {
        if (shouldRedirectHome) {
            window.location.href = '/';
            dispatch(resetRedirectHome());
        }
    }, [shouldRedirectHome]);

    // If they are not logged in just put in the routes with no sidebar. It will go to Auth flows.
    if (!childProps.isAuthenticated || childProps.needsVerification) {
        return (
            <div className="App logged-out">
                {routes}
            </div>
        );
    }

    // Render full logged in view
    return (
        <div>
            {isTopNavEnabled() && <Sidebar {...childProps} />}
            <div className="appWindow" data-testid="appContainer">
                <CssBaseline/>
                <Notification/>
                {!isTopNavEnabled() && <Sidebar {...childProps} />}
                <main className="contentView">
                    {routes}
                </main>
            </div>
        </div>
    );
}

export default AppLayout;