import React from 'react';
import PositivityFeedbackCell from "../positivity/PositivityFeedbackCell";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import Masonry from "react-masonry-css";
import useInterval from 'beautiful-react-hooks/useInterval';

import {getLocalDateStringFromUTC} from "@/common/LocalTimeStamp";
import {hideAuthorsFromFocusBoard} from "@/shared/access";
import _ from "lodash";
import {withRouter} from "react-router";
import useDocumentTitle from "../../common/UseDocumentTitle";

function PublicFeedbackIndex(props) {
    useDocumentTitle('Feedback board');
    const {token} = props.match.params;
    const LIST_TAB = "LIST";
    const FOCUS_TAB = "FOCUS";
    const FULL_SCREEN_CLASS = "fullScreen";
    const [feedback, setFeedback] = React.useState({});
    const [activeTab, setActiveTab] = React.useState("");
    const [lastFeedbackLoadedAt, setLastFeedbackLoadedAt] = React.useState(0);
    const [showCarousel, setShowCarousel] = React.useState(false);
    const engageLogoUrl = '/powerdms-engage-FKA-logo-white.svg';
    const [currentItem, setCurrentItem] = React.useState(1);
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.remove(FULL_SCREEN_CLASS);
        if (activeTab === FOCUS_TAB) {
            document.body.classList.add(FULL_SCREEN_CLASS);
            setShowCarousel(true);
        }
    }, [activeTab])

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        }
    };

    const backgroundColor1 = {
        backgroundColor: "#6550B9"
    }
    const backgroundColor2 = {
        background: "#0096B9"
    }
    const backgroundColor3 = {
        background: "#0D74CE"
    }

    const colors = [
        backgroundColor1,
        backgroundColor2,
        backgroundColor3
    ]
    const loadFeedback = (since) => {
        if (requestInProgress) {
            return;
        }
        setRequestInProgress(true);
        let params = '';

        if (since > 0) {
            params += '?since=' + since;
        }
        API.get('Unauthorized', '/u/feedback-public/' + token + params, { timeout: 90000 })
            .then(response => {
                    if (response.status !== "NO_NEW_DATA") {
                        setFeedback(Object.assign({}, response));
                        if(feedback.lastUpdated) {
                            const timestamp = new Date(feedback.lastUpdated).getTime();
                            setLastFeedbackLoadedAt(timestamp);
                        }
                        setActiveTab(response.viewType);
                    }
                })
            .catch(error => {
                    let errorMessage = "Could not load public feedbacks: ";
                    if (!error.response) {
                        errorMessage += "check your network connection. This message will disappear once the connection is restored.";
                    } else {
                        errorMessage += error.response?.data?.message;
                    }
                    toast(errorMessage, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
            .finally(() => {
                setRequestInProgress(false);
            });
    }

    useInterval(() => {
        loadFeedback(lastFeedbackLoadedAt);
    }, 90000);

    React.useEffect(() => {
        loadFeedback(lastFeedbackLoadedAt);
    }, [])

    const handleSlideChange = (index) => {
        setCurrentItem(index + 1);
    };
    return (
        <div className={`rootView ${activeTab === 'FOCUS' ? "publicFocusedFeedbackView" : "publicFeedbackView"} positivityView`}>
            <div className="navBar">
                <div
                    style={{
                        backgroundImage: `url("${engageLogoUrl}")`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: '135px',
                        height: '35px'
                    }}
                    className="logo">
                </div>
                <h1 className="title">Feedback Board</h1>
            </div>
            {
                activeTab === LIST_TAB ? (
                    <TransitionGroup className="feedbackList tableView">
                        <Masonry className={"feedback-masonry"} breakpointCols={2}
                                 columnClassName={"feedback-masonry-column"}>
                            {
                                _.map(feedback.items, function (f, i) {
                                    let color = colors[(i % 3)];
                                    return (
                                        <CSSTransition
                                            key={`${f.id}-${i}`}
                                            timeout={5000}
                                            classNames="feedback-item"
                                        >
                                            <PositivityFeedbackCell
                                                extraClasses={""}
                                                feedbackStyle={color}
                                                feedbackText={f.feedback}
                                                surveyId={f.surveyId}
                                                feedbackSubject={f.feedbackSubject}
                                                encounterPersonnel={f.encounterPersonnel}
                                            />
                                        </CSSTransition>
                                    )
                                })
                            }
                        </Masonry>
                    </TransitionGroup>
                ) : (
                    <div className="focusView">
                        <div className={"feedbackList tableView"}>
                            {(showCarousel &&
                                <Carousel
                                    centerMode={false}
                                    showArrows={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    infiniteLoop={true}
                                    showThumbs={false}
                                    autoPlay={true}
                                    interval={10000}
                                    transitionTime={600}
                                    stopOnHover={false}
                                    onChange={handleSlideChange}
                                >
                                    {
                                        _.map(feedback.items, function (f, i) {
                                            let color = colors[(i % 3)];
                                            let responders, all, callTakers, responderNames, callTakerNames,
                                                allNames = new Set();

                                            if (f.personnel != null) {
                                                responders = new Set(f.personnel.filter(ep => ep.type === "RESPONDER").map(resp => resp.name));
                                                callTakers = new Set(f.personnel.filter(ep => ep.type === "CALLTAKER").map(ct => ct.name));
                                                all = new Set(f.personnel.map(p => p.name));
                                                responderNames = Array.from(responders);
                                                callTakerNames = Array.from(callTakers);
                                                allNames = Array.from(all);
                                            }

                                            return (
                                                <div style={Object.assign(color)}
                                                     className="feedbackCell focusedFeedback" key={f.surveyAnswerId}>
                                                    {f.feedback != null && f.feedback.length >= 400 ?
                                                        <div className="feedbackText small">{f.feedback}</div> :
                                                        f.feedback != null && f.feedback.length >= 200 && f.feedback.length < 400 ?
                                                            <div className="feedbackText medium">{f.feedback}</div> :
                                                            <div className="feedbackText big">{f.feedback}</div>
                                                    }
                                                    <div style={styles.container}>
                                                        <span
                                                            className="feedbackDate">{getLocalDateStringFromUTC(f.date, "MM/DD/YYYY, h:mm:ss a")}</span>
                                                        {
                                                            f.personnel && f.feedbackSubject && f.feedbackSubject === "CALLTAKER" && !hideAuthorsFromFocusBoard() ? (
                                                                <p className="feedbackAuthor"
                                                                   style={{fontSize: '20px'}}>{callTakerNames.join(' - ')}</p>
                                                            ) : null
                                                        }
                                                        {
                                                            f.personnel && f.feedbackSubject && f.feedbackSubject === "RESPONDER" && !hideAuthorsFromFocusBoard() ? (
                                                                <p className="feedbackAuthor"
                                                                   style={{fontSize: '20px'}}>{responderNames.join(' - ')}</p>
                                                            ) : null
                                                        }
                                                        {
                                                            f.personnel && f.feedbackSubject && f.feedbackSubject === "ALL" && !hideAuthorsFromFocusBoard() ? (
                                                                <p className="feedbackAuthor"
                                                                   style={{fontSize: '20px'}}>{allNames.join(' - ')}</p>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>)}
                        </div>
                    </div>
                )
            }
            {activeTab === FOCUS_TAB && <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: "absolute",
                    padding: "0",
                    margin: "0",
                    overflow: "hidden",
                    clip: "rect(0, 0, 0, 0)",
                    border: "0",
                }}
            >
                Item {feedback?.items?.length > 0 ? currentItem : 0} of {feedback?.items?.length || 0}
            </div>}
        </div>
    );
}

export default withRouter(PublicFeedbackIndex);