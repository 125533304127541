import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateExcludeComment} from "../../shared/survey/actions";
function ExcludeRatingInputCell(props) {
    const {
        inputRef, defaultValue
    } = props;
    const [inputFocused, setInputFocused] = React.useState(false);
    const dispatch = useDispatch();
    const excludeComment = useSelector(state => state.survey.excludeComment);

    React.useEffect(() => {
        if (defaultValue) {
            dispatch(updateExcludeComment(defaultValue));
        }
    }, []);
    const onInputFocused = () => {
        setInputFocused(true);
    }
    const onInputChange = (event) => {
        dispatch(updateExcludeComment(event.target.value));
    }

    const field = () => {
        return <textarea
            value={excludeComment}
            onChange={onInputChange}
            onFocus={onInputFocused}
            ref={inputRef}
            autoFocus={true}/>;
    }

    return (
        <div
            className={`cell inputCell ${inputFocused ? "isFocused" : ""} ${excludeComment.length ? "hasInput" : ""}`}>
            {field()}
        </div>
    );
}
export default ExcludeRatingInputCell;